.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body{
  background-color: #fefefe !important;
}

h1 {
  font-size: 2.5rem !important;
}

h2{
  text-align: left;
  margin-top: 20px !important;
  border-top: 1px solid lightgray;
  padding-top: 10px;
}

.App-link {
  color: #61dafb;
}

.new-cat-fab{
  position: fixed !important;
  bottom: 85px;
  right: 20px;
}

.MuiFab-secondary{
  color: black;
}

.view-toggle-fab{
  position: fixed !important;
  bottom: 20px;
  right: 20px;
}

.fab-visible{
  opacity: 1;
  transition: all 1s;
}

.fab-hidden{
  opacity: 0;
  transition: all 1s;
  pointer-events: none;
}

button.add-todo-button {
  border: none;
  height: 40px;
  color: #2F4F4F;
  background: transparent;
  text-align: left;
  padding-top: 5px;
}

button.add-todo-button:focus {
  border: none;
  outline: none;
}

button.add-todo-button:focus{
  outline:none !important;
}
.header-row{
  display: flex;
  align-items: center;
}

.img-row{
  margin-bottom: 5px;
  margin-left: 5px;
}

.heart-img-m{
  height: 24px;
  margin-top: 5px;
}

.heart-img-w{
  height: 33px;
  margin-bottom: 4px;  
}

.image-icon{
  margin-left: 5px;
  margin-bottom: 3px;
}

.image-modal-body{
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.modal-content{
  width: fit-content !important;
  margin: auto !important;

}

.image-modal-image{
  max-width: 100%;
}

.add-todo{
  display: flex;
  width: 300px;
  margin-left: 5px;
}

.todo-column{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 5px;
  text-align: left;
}

.todo-column-item{
  margin-bottom: 5px;
  display: flex;
  width: 95%;
  max-width: 500px;
}

.new-todo-input{
  margin-top: 5px;
  padding-left: 15px; 
  height: 35px;
}

.close-icon{
  cursor: pointer;
  margin-left: auto;
}

label {
  min-width: 40px;
}

.container{
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  padding-top: 0.5rem !important;
}